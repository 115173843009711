<template>
  <div class="join">
    <div class="banner" :style="{ backgroundImage: `url(${bannerUrl})` }">
      <div class="banner-box">
        <div class="banner-title">新闻中心</div>
        <div class="title-eng">News&nbsp;Center</div>
        <div class="banner-msg">
          快捷浏览，掌握灵鹿一手资讯。
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-box">
        <div class="news-list">
          <div class="news-item" v-for="(item) in list" :key="item.id" @click="handleRouter(item.id)">
            <img :src="item.imgUrl" alt="">
            <div class="news-content">
              <h3>{{ item.title | titleFilter }}</h3>
              <p>{{ item.msg | txtFilter }}</p>
            </div>
          </div>
          <div class="news-item" v-for="(i,j) in emptyNum" :key="j     +     100"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import imgsUrl from '../utils/imgAddress';


export default {
  data() {
    return {
      emptyNum: 0,
      bannerUrl: imgsUrl.bannerMd.newsBanner,
      list: this.$store.state.newsList
    }
  },
  filters: {
    txtFilter(val) {
      if (val.length > 120) {
        let txt = val.slice(0,120)+"..."
        return txt
      }else{
        return val
      }
    },
    titleFilter(val) {
      if (val.length > 20) {
        let txt = val.slice(0,20)+"..."
        return txt
      }else{
        return val
      }
    }
  },
  mounted() {
    this.emptyNum = 3 - (this.list.length % 3)
  },
  methods: {
    handleRouter(id) {
      this.$router.push('/newscentercontent?id=' + id)
    }
  }
}
</script>
<style scoped lang="scss">
.join {
  .banner {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-position: top;
    background-position-x: center;
    background-position-y: top;
    position: relative;
    letter-spacing: 5px;

    .banner-box {
      position: absolute;
      top: 30%;
      left: 10%;
      color: #d8d8d8;

      .banner-title {
        width: 220px;
        font-size: 48px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
      }

      .title-eng {
        width: 343px;
        font-size: 48px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
        text-align: left;
      }

      .banner-msg {
        letter-spacing: 2px;
        color: #999999;
        font-size: 22px;
      }
    }
  }

  .main {
    height: auto;
    margin: 120px 0;
    display: flex;
    justify-content: center;

    .main-box {
      width: 1400px;

      .news-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .news-item {
          width: 30%;
          padding: 10px 20px;
          cursor: pointer;


          img {
            width: 100%;

          }

          .news-content {
            margin-top: -5px;
            background: #262626;

            padding: 18px;

            h3 {
              color: #fff;
              font-size: 18px;
              padding: 0;
              margin: 0;
              line-height: 1.5;
            }

            p {
              height: 100px;
              color: #808080;
              font-size: 14px;
              line-height: 20px;

            }
          }

        }
      }



    }
  }


}
</style>
